import React from "react"
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import SEO from '../components/seo'

class Terms extends React.Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Terms of Service',
        page: '/terms'
      });
      window.cmAgent.dispatch();
    }

    if (window && window.gtag) {
      window.gtag('config', 'G-KTYYBQVLCK', {'page_path': '/privacy'});
    }
  }

  render() {

    return <div>
      <SEO title="Terms of Service | Notifuse" />

      <Topbar />

      <div className="container">
        
        <div className="terms">
          <h1>Terms of Service</h1>

          <p>Last updated: November 13, 2020. Effective date: November 13, 2020.</p>

          <p>Notifuse may revise these terms of use for its service at any time without notice. By using this service you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
          <p>Notifuse is a service distributed by the company Captain Metrics SAS, registered at 4 rue Paul Claudel 41100 Vend&ocirc;me, France (registration number: 848833559). Our technical infrastructure is provided by Google Cloud.</p>

          <h2>1. Use License</h2>

          <p>Permission is granted to temporarily download one copy of the materials (information or software) on Notifuse&#39;s service for personal use only (whether it be in the context of an individual or an organization). This is the grant of a license, not a transfer of title, and under this license you may not:</p>
          <ul>
            <li>Modify or copy the materials;</li>
            <li>Attempt to decompile or reverse engineer any software contained on Notifuse&#39;s service;</li>
            <li>Remove any copyright or other proprietary notations from the materials; or</li>
            <li>Transfer the materials to another person or &ldquo;mirror&rdquo; the materials on any other server.</li>
          </ul>
          <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Notifuse at any time.</p>

          <h2>2. Disclaimer</h2>

          <p>The materials on Notifuse&#39;s service are provided &ldquo;as is&rdquo;. Notifuse makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Notifuse does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet service or otherwise relating to such materials or on any sites linked to this site.</p>

          <h2>3. Limitations</h2>

          <p>In no event shall Notifuse or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Notifuse&#39;s service, even if Notifuse or a Notifuse authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

          <h2>4. Revisions and Errata</h2>

          <p>The materials appearing on Notifuse&#39;s service could include technical or contents errors. Notifuse does not warrant that any of the materials on its service are accurate, complete, or current. Notifuse may make changes to the materials contained on its service at any time without notice. Notifuse does not, however, make any commitment to update the materials.</p>

          <h2>5. Notifications sent</h2>

          <p>Notifuse&nbsp;does not review the notifications that are being sent between parties (our customer and its users). The notification originator (aka the sender) is the sole responsible of its contents. We strictly prohibit discrimination, hate, pornography and pedophilic messages and files to be exchanged through our services.</p>

          <h2>6. Billing</h2>

          <p>Notifuse&nbsp;is offering free and paid services. Paid plans (Pro, Premium & Enterprise) are subject to fees. Fees are post-paid every month. All Fees are non-refundable, i.e. there are no refunds or credits for periods where the Client did not use an activated Account, used it only partially, or deactivated the Account or terminated these Terms during an ongoing payment interval.</p>
          <p>All Notifuse paid plans come with a 14 days trial which can be canceled from https://console.notifuse.com by switching back to the free plan. After the trial, the subscription is then considered as active and fees are applied. The remaining trial period (end date) is available from the Billing tab in the Organization Settings.</p>
          <p>Notifuse also offers yearly payments. Switching to yearly payments can be done from the plans tab in the dashboard. Yearly payments are non-refundable once paid.</p>
          <p>If fees are not paid in the following 14 days, Supplier has the right to permanently delete the Account, including all Client Data therein.</p>
          <p>When subscribing to Notifuse paid service (upgrading a plan), or adding a payment method, the user must ensure the email address provided for the Notifuse account is valid, able to receive emails from us, and emails we will deliver to that email inbox are read by the user (eg. paid invoices or unpaid invoice notifications).</p>
          <p>The Notifuse paid service shall be used by legal entities only (businesses and non-profits). It should not be purchased by private individuals, though they can still use the product in its free version.</p>
          <p>By adding a payment method to their Notifuse account, the user consents to be charged for any active paid subscription, as well as paid subscriptions coming out of trial period. We will automatically attempt to pay any past and present unpaid invoices on the user account when a new payment method is added, using that new payment method. No fees will be incurred for free subscriptions even if a card is registered on the user account.</p>

          <h2>7. Cancellation</h2>

          <p>User can delete their account anytime by contacting Notifuse at support@notifuse.com</p>

          <h2>8. Service Terms of Use Modifications</h2>

          <p>Notifuse&nbsp;may revise these terms of use for its service at any time without notice. By using this service you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>

          <h2>9. Governing Law</h2>

          <p>Any claim relating to Notifuse&#39;s service shall be governed by the laws of France without regard to its conflict of law provisions.</p>
          <p>By using Notifuse, you commit to use the service in accordance with these principles. On its side, Notifuse undertakes to provide a reliable service and comprehensive communication.</p>

        </div>
      </div>

      <Footer />
    </div>
  }
}

export default Terms
